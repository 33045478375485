import React, { Component } from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {
	Col,
	Row,
	Form,
	FormGroup,
	Label,
	Input,
	Button,
	FormFeedback,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from "reactstrap";
import { connect } from "react-redux";
import { loginUser } from "../state/user";
import ContentContainer from "../components/ContentContainer";
import UserIcon from "../images/icon-user.svg";
import LockIcon from "../images/icon-lock.svg";
import { Link } from "gatsby";
import AuthedRoute from "../components/AuthedRoute";

class Login extends Component {

	state = {
		email: "",
		password: "",
		validation: {
			email: false,
			password: false,
		},
		touched: false,
		error: "",
	};

	validatePassword(e) {
		const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)\S{8,}$/;
		this.setState({ validation: { ...this.state.validation, password: passwordRegex.test(e.target.value) } });
	}

	validateEmail(e) {
		// eslint-disable-next-line
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		this.setState({ validation: { ...this.state.validation, email: emailRegex.test(e.target.value) } });
	}

	handleChange(e) {
		const { target } = e;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const { name } = target;
		this.setState({
			[name]: value,
		});
	}

	async handleSubmit(e) {
		e.preventDefault();
		await this.setState({
			touched: true,
		});

		if (Object.values(this.state.validation).reduce((a, b) => a && b)) {
			this.props.dispatch(loginUser(this.state.email, this.state.password));
		}
	}

	render() {
		const { touched, email, password } = this.state;
		return (
			<AuthedRoute inverted>
				<Layout>
					<SEO title="Login"/>
					<ContentContainer half>
						<Row>
							<Col sm="12">
								<div className="text-center mw-75 d-block mx-auto">
									<h1 className="font-weight-bold mt-5">Da bist du ja endlich wieder!</h1>
									{/* <p className="mt-3 color-subtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's since the 1500s</p> */}
								</div>
								<Form onSubmit={ e => this.handleSubmit(e) } className="mw-50 d-block mx-auto">
									<FormGroup>
										<Label for="email">Email</Label>
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText className="round-left input-group-icon">
													<img src={UserIcon} alt="user icon prefix" />
												</InputGroupText>
											</InputGroupAddon>
											<Input type="email" name="email" id="email" placeholder="muster@mail.de"
												   className="round-right"
												   value={ email }
												   valid={ touched && this.state.validation.email }
												   invalid={ touched && !this.state.validation.email }
												   onChange={ e => {
													   this.validateEmail(e);
													   this.handleChange(e)
												   } }/>
										</InputGroup>
										<FormFeedback valid>
											Sieht gut aus!
										</FormFeedback>
										<FormFeedback>
											Irgendwas ist komisch..
										</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="password">Password</Label>
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText className="round-left input-group-icon">
													<img src={LockIcon} alt="lock icon prefix" />
												</InputGroupText>
											</InputGroupAddon>
											<Input type="password" name="password" id="password" placeholder="********"
													className="round-right"
													value={ password }
													valid={ touched && this.state.validation.password }
													invalid={ touched && !this.state.validation.password }
													onChange={ e => {
														this.validatePassword(e);
														this.handleChange(e)
													} } />
										</InputGroup>
										<FormFeedback valid>
											<span role="img" aria-label="thumbsup emoji">👍</span>
										</FormFeedback>
										<FormFeedback>
											Passwort unsicher! (8 Zeichen, min. 1 Buchstabe, min. 1 Zahl)
										</FormFeedback>
									</FormGroup>
									<p className="feedback-error">{ this.state.error || this.props.error.login }</p>
									<Button color="primary" block className="round mt-4 py-2">Anmelden</Button>
									<div className="d-flex justify-content-between color-subtext mt-1 px-4">
										<Link to="/signup" className="font-weight-bold">Account erstellen</Link>
										<Link to="/reset-password">Passwort vergessen?</Link>
									</div>
								</Form>
							</Col>
						</Row>
					</ContentContainer>
				</Layout>
			</AuthedRoute>
		);
	}
}

export default connect(state => ({
	error: state.user.error,
}), null)(Login);
